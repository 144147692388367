import React, { CSSProperties, FC, ReactElement } from 'react';

import ReactQrCode from 'react-qr-code';

import './QrCode.scss';

interface QrCodeProps {
    link: string;
    style?: CSSProperties;
    className?: string;
}

const QrCode: FC<QrCodeProps> = ({
    link,
    style,
    className = '',
}): ReactElement => (
    <ReactQrCode
        value={link}
        style={style}
        className={`qr-code ${className}`}
    />
);

export default QrCode;
