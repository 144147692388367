import React, { FC, ReactElement } from 'react';

import { NewsFeed, NewsRoom } from '../../compositions';
import { Article } from '../../entities/Article/Article';

import './VideowallContainer.scss';

interface VideowallContainerProps {
    articles: Article[];
    className?: string;
}

export const initialPresentationDelay = 1;

const VideowallContainer: FC<VideowallContainerProps> = ({
    articles,
    className = '',
}): ReactElement => (
    <div className={`videowall-container ${className}`}>
        <picture className="videowall-container__picture">
            <source srcSet="/background.webp" type="image/webp" />
            <source srcSet="/background.jpeg" type="image/jpeg" />
            <img
                src="/background.jpeg"
                alt="Background"
                className="videowall-container__image"
            />
        </picture>
        <NewsRoom />
        <NewsFeed articles={articles} />
    </div>
);

export default VideowallContainer;
