import React, {
    AnimationEvent,
    CSSProperties,
    FC,
    ReactElement,
    useState,
} from 'react';

import classNames from 'classnames';

import { CheckIntersection, QrCode } from '../../components';
import { Article } from '../../entities/Article/Article';

import './NewsSlider.scss';

interface NewsSliderProps {
    articles: Article[];
    className?: string;
}

const NewsSlider: FC<NewsSliderProps> = ({
    articles,
    className = '',
}): ReactElement => {
    const [animationKey, setAnimationKey] = useState<string>('');
    const newsSlideDurationModifier = 4.5;
    const newsSlideDuration = (articles.length * newsSlideDurationModifier) + 12.5;

    const handleAnimationIteration = (event: AnimationEvent<HTMLDivElement>): void => {
        setAnimationKey(`${event.timeStamp}-${event.animationName}`);
    };

    const cssVariables = {
        '--news-slide-duration': `${newsSlideDuration}s`,
        '--left-articles-y-transform': `${(articles.length * -12.5) + -100}%`,
        '--right-articles-y-transform': `${(articles.length * -12.5) + (-100 + (articles.length * -newsSlideDurationModifier))}%`,
    } as CSSProperties;

    return (
        <div
            key={animationKey}
            onAnimationIteration={handleAnimationIteration}
            style={cssVariables}
            className={`news-slider ${className}`}
        >
            {articles.map(article => {
                const imageWrapperClassnames = classNames('news-slider__image-wrapper', {
                    'news-slider__image-wrapper--has-image': article.image.large,
                });

                return (
                    <CheckIntersection key={article.id}>
                        {isVisible => (
                            <div
                                className={`news-slider__block-wrapper ${isVisible ? 'news-slider__block-wrapper--is-visible' : ''}`}
                            >
                                <div className={imageWrapperClassnames}>
                                    {article.image.large && (
                                        <img
                                            src={article.image.large}
                                            alt={article.title}
                                            className="news-slider__image"
                                        />
                                    )}
                                    <div className="news-slider__title-wrapper">
                                        <h2 className="news-slider__title">{article.title}</h2>
                                        <div className="news-slider__qr-code-wrapper">
                                            <QrCode
                                                link={`${process.env.REACT_APP_AALBERTS_URL}/progress/${article.slug}`}
                                                className="news-slider__qr-code"
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </CheckIntersection>
                );
            })}
        </div>
    );
};

export default NewsSlider;
