import React, {
    createRef,
    FC,
    ReactElement,
    useEffect,
    useState,
} from 'react';

interface CheckIntersectionProps {
    once?: boolean;
    alwaysVisible?: boolean;
    shouldBeFullyInView?: boolean;
    className?: string;
    children: (isVisible: boolean) => ReactElement;
}

const CheckIntersection: FC<CheckIntersectionProps> = ({
    once,
    alwaysVisible,
    shouldBeFullyInView,
    className = '',
    children,
}): ReactElement => {
    const [isVisible, setVisibility] = useState(false);
    const childRef = createRef<HTMLDivElement>();
    const observerThreshold = 0.4;

    useEffect((): void => {
        if ('IntersectionObserver' in window && childRef.current) {
            const onIntersection = (entries: IntersectionObserverEntry[]): void => {
                for (let i = 0; i < entries.length; i += 1) {
                    if (entries[i].isIntersecting || entries[i].boundingClientRect.top < 0) {
                        setVisibility(true);
                    } else if (!once) {
                        setVisibility(false);
                    }
                }
            };

            const observer = new IntersectionObserver(onIntersection, { threshold: shouldBeFullyInView ? 1 : observerThreshold });
            observer.observe(childRef.current);
        } else {
            setVisibility(true);
        }
    }, [childRef]);

    return (
        <div ref={childRef} className={className}>
            {children ? children(alwaysVisible || isVisible) : null}
        </div>
    );
};


export default CheckIntersection;
