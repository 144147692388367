import React, { FC, ReactElement } from 'react';

import { QrCode } from '../../components';
import { Article } from '../../entities/Article/Article';

import './NewsItem.scss';

interface NewsItemProps {
    article: Article;
    showAuthor: boolean;
    className?: string;
}

const NewsItem: FC<NewsItemProps> = ({
    article,
    showAuthor,
    className = '',
}): ReactElement => (
    <div className={`news-item ${className}`}>
        <img
            src={article.image.original}
            alt={article.image.original ? article.title : ''}
            className="news-item__image"
        />
        <div className="news-item__content">
            <div className="news-item__content-left">
                {(article.author && showAuthor) && (
                    <div className="news-item__author-wrapper">
                        {article.author.url && (
                            <img
                                src={article.author.url}
                                alt={article.author.name}
                                className="news-item__author-image"
                            />
                        )}
                        <h3 className="news-item__author">{article.author.name}</h3>
                    </div>
                )}

                <div className="news-item__title-wrapper">
                    <h1 className="news-item__title">{article.title}</h1>
                </div>

                {(article.highlights && article.highlights.length > 0) && (
                    <div className="news-item__highlight-wrapper">
                        {article.highlights.map((highlight) => (
                            <div className="news-item__highlight">
                                {highlight}
                            </div>
                        ))}
                    </div>
                )}
            </div>

            <div className="news-item__content-right">
                <div className="news-item__qr-code-wrapper">
                    <QrCode
                        link={`${process.env.REACT_APP_AALBERTS_URL}/progress/${article.slug}`}
                        className="news-item__qr-code"
                    />
                </div>
            </div>
        </div>
    </div>
);

export default NewsItem;
